import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const Form = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid gray;
`;

const AddModal = ({ zipUpload, handleHide }) => {
  const [uploads, setUploads] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const path = `/policies/${zipUpload.resource_id}/uploads.json`
    axios.get(path).then(({ data }) => {
      const filtered = data.filter(upload => (
        upload.file_file_name && upload.file_file_name.endsWith('.pdf')
      ));

      setUploads(filtered);
    });
  }, []);

  const addUpload = ({ upload, file }) => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append('zip_upload_item[zip_id]', zipUpload.id);

    if (upload) {
      formData.append('zip_upload_item[upload_id]', upload.id);
    }

    if (file) {
      formData.append('file', file);
    }

    const token = document.querySelector('[name=csrf-token]').content

    axios({
      method: 'POST',
      url: `/zip_upload_items`,
      headers: { 'X-CSRF-TOKEN': token },
      data: formData,
    }).then(() => handleHide());
  };

  return (
    <Modal show centered size="lg" style={{minHeight: 50}}>
      {submitting ? (
        <span>Processing...</span>
      ) : (
        <>
          <Modal.Body>
            <p>
              Click the "Add" link beside a file to add to the ZIP. Or, use
              the "Browse..." button below to upload a file from your computer.
            </p>

            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date Added</th>
                  <th>Add to ZIP</th>
                </tr>
              </thead>
              <tbody>
                {uploads.map(upload => (
                  <tr key={upload.id}>
                    <td>{upload.name}</td>
                    <td>{moment(upload.created_at).format('M/D/YYYY')}</td>
                    <td
                      style={{cursor: 'pointer'}}
                      onClick={() => addUpload({ upload })}
                    >
                     Add
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Form>
              <input
                type="file"
                accept="application/pdf"
                onChange={e => addUpload({ file: e.target.files[0] })}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <input type="button" value="Cancel" onClick={handleHide} />
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
};

export default AddModal;
