import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { CompactPicker } from 'react-color';

import { CUSTOM_TEXT } from './index';
import './styles.css';

const Field = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
`;

const Label = styled.label`
  width: 100px;
`;

const UploadAreaModal = props => {
  const {
    area, handleHide, liveUpdateArea, saveUpdates, signatureTypes
  } = props;

  const [defaultValues] = useState({...area});
  if (CUSTOM_TEXT === defaultValues.body) { defaultValues.body = ''; }
  const { register, handleSubmit, control, watch } = useForm({ defaultValues });

  const areaFields = watch();

  useEffect(() => {
    if (JSON.stringify(area) !== JSON.stringify(areaFields)) {
      liveUpdateArea(areaFields);
    }
  }, [areaFields]);

  const onSubmit = useCallback(input => {
    saveUpdates(input);
    handleHide();
  });

  const handleRemove = () => {
    saveUpdates({ ...area, _destroy: true });
    handleHide();
  };

  const handleCancel = () => {
    handleHide();
    saveUpdates();
  };

  let body = (
    <Modal.Body>
    </Modal.Body>
  );

  const height = (
    <Field>
      <Label>Height:</Label>
      <input type="number" min="1" max="500" {...register('height')} />
    </Field>
  );

  const width = (
    <Field>
      <Label>Width:</Label>
      <input type="number" min="1" max="500" {...register('width')} />
    </Field>
  );

  const fontSize = (
    <Field>
      <Label>Font Size:</Label>
      <input type="number" min="1" max="100" {...register('font_size')} />
    </Field>
  );

  const bold = (
    <Field>
      <Label>Bold</Label>
      <select {...register('bold')}>
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>
    </Field>
  );

  if ([1, 7].includes(area.field)) { // Add Text, Add Comment
    body = (
      <Modal.Body>
        <Field>
          <Label>Text:</Label>
          <input size="50" type="text" {...register('body')} />
        </Field>

        {fontSize}

        <Field>
          <Label>Font Style:</Label>
          <select {...register('font_style')}>
            <option value="1">Normal</option>
            <option value="2">Cursive</option>
          </select>
        </Field>

        {bold}

        <Field>
          <Label>Underline</Label>
          <select {...register('underline')}>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </Field>

        <Field>
          <Label>Color:</Label>
          <Controller
            name="color"
            control={control}
            render={({ field }) => (
              <CompactPicker
                color={field.value}
                onChangeComplete={color => field.onChange(color.hex)}
              />
            )}
          />
        </Field>
      </Modal.Body>
    );
  } else if ([2,5].includes(area.field)) { // Highlight, Initial
    body = (
      <Modal.Body>
        {height}
        {width}
      </Modal.Body>
    );
  } else if (3 === area.field) { // Checkmark
    body = (
      <Modal.Body>
        <Field>
          <Label>Checked:</Label>
          <select {...register('body')}>
            <option value="">No</option>
            <option value="✓">Yes</option>
          </select>
        </Field>

        {fontSize}
      </Modal.Body>
    );
  } else if ([4,6].includes(area.field)) { // Signature Line, Strikout
    body = (
      <Modal.Body>
        {width}
        {bold}
      </Modal.Body>
    )
  } else if (8 === area.field || area.field >= 750) { // Signature Image
    body = (
      <Modal.Body>
        <Field>
          <Label>Image:</Label>
          <select {...register('field')}>
            {signatureTypes.map(signatureType => (
              <option key={signatureType[0]} value={signatureType[0]}>
                {signatureType[1]}
              </option>
            ))}
          </select>
        </Field>
        {width}
      </Modal.Body>
    )
  }

  return (
    <Modal show centered size="lg" contentClassName="modal-translucent">
      {body}
      <Modal.Footer style={{justifyContent: 'space-between'}}>
        <input type="submit" value="🗑 Remove" onClick={handleRemove} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="submit" value="Save" />
          <input type="button" value="Cancel" onClick={handleCancel} />
        </form>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadAreaModal;
