import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import UploadAreaModal from './UploadAreaModal';

export const CUSTOM_TEXT = 'CUSTOM TEXT (double-click to change)';

const DragTypes = {
  FIELD: 'field',
  AREA: 'area',
};

const FieldType = ({ fieldId, label }) => {
  const [{ isDragging }, drag] = useDrag({
    type: DragTypes.FIELD,
    item: () => ({ fieldId, label }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <div
      style={{
        display: 'inline-block',
        padding: 5,
        margin: 5,
        border: '1px solid black'
      }}
      ref={drag}
    >
      {label}
    </div>
  );
};

const Area = ({ area, liveUpdateArea, saveUpdates, signatureTypes }) => {
  const [showModal, setShowModal] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: DragTypes.AREA,
    item: () => area,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const classNames = [];

  const style = {
    backgroundColor: '#FFA50020',
    position: 'absolute',
    left: area.left,
    top: area.top,
    fontSize: area.font_size,
    lineHeight: '92%',
  };

  if (area.height) {
    style.height = area.height;
  }

  if (area.width) {
    style.width = area.width;
  }

  if (['1', 1].includes(area.underline)) {
    style.textDecoration = 'underline';
  }

  if (['1', 1].includes(area.bold)) {
    style.fontWeight = 700;
  }

  if ([2, '2'].includes(area.font_style)) {
    classNames.push('cursive');
  }

  let content = area.field;

  if (1 === area.field) { // Add Text
    content = area.body;
    style.color = area.color;
  } else if (2 === area.field) { // Highlight
    content = '';
    style.backgroundColor = '#FFEA0080';
  } else if (3 === area.field) { // Checkmark
    content = area.body || '';
    style.border = '1px solid black';
    style.textAlign = 'center';
    style.lineHeight = `${area.font_size}px`;
    style.height = area.font_size;
    style.width = area.font_size - 2;
  } else if (4 === area.field) { // Signature Line
    content = 'Sign Here';
    if (['1', 1].includes(area.bold)) {
      style.borderTop = '3px solid black';
    } else {
      style.borderTop = '1px solid black';
    }
    style.background = 'none';
    style.fontSize = 11;
  } else if (5 === area.field) { // Initial box
    style.height = undefined;
    content = (
      <>
        <div
          style={{
            border: '1px solid black',
            height: area.height,
            width: '100%',
          }}
        >
        </div>
        <span style={{fontSize: 11}}>Initial Here</span>
      </>
    );
  } else if (6 === area.field) { // Strikeout
    content = '';
    if (['1', 1].includes(area.bold)) {
      style.borderTop = '3px solid red';
    } else {
      style.borderTop = '1px solid red';
    }
    style.background = 'none';
    style.height = 6;
  } else if (7 === area.field) { // Add Comment
    content = area.body;
    style.border = '1px solid gray';
    style.color = '#606060';
    style.padding = '3px';
  } else if (8 === area.field) { // Signature
    content = 'Image - double-click to choose'
  } else if (area.field >= 750) { // Signature (chosen)
    style.backgroundColor = 'transparent';

    const signatureType = signatureTypes.
      find(type => type[0] === parseInt(area.field));

    content = (
      <img
        style={{width: area.width}}
        src={`/assets/report_areas/${signatureType[2].attr}?v=20230428`}
      />
    );
  }

  return (
    <>
      <div
        onDoubleClick={() => setShowModal(true)}
        ref={drag}
        style={style}
        className={classNames.join(' ')}
      >
        {content}
      </div>
      {showModal && (
        <UploadAreaModal
          area={area}
          handleHide={() => setShowModal(false)}
          liveUpdateArea={liveUpdateArea}
          saveUpdates={saveUpdates}
          signatureTypes={signatureTypes}
        />
      )}
    </>
  );
};

const getAreas = (data, page) => {
  return data.upload_areas.map(area => (
    Object.fromEntries(
      Object.entries(area)
        .filter(([key]) => !['created_at', 'updated_at'].includes(key))
    )
  )).filter(area => area.page === page);
};

const Page = props => {
  const {
    id,
    imageUrl,
    fieldTypes,
    signatureTypes,
    page,
    lastSaves,
    setLastSaves
  } = props;

  const [areas, setAreas] = useState([]);
  const imageRef = useRef(null);

  useEffect(() => {
    axios.get(`/zip_upload_items/${id}`).then(({ data }) => {
      setAreas(getAreas(data, page));
    });
  }, [page]);

  const saveAreas = useCallback(({ id, areas, reload }) => {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    axios({
      method: 'PUT',
      url: `/zip_upload_items/${id}`,
      headers: { 'X-CSRF-TOKEN': token },
      data: { areas },
    }).then(({ data }) => {
      if (reload) {
        setAreas(getAreas(data, page));
      }
    });
  }, [areas, setAreas]);

  const [, drop] = useDrop({
    accept: [DragTypes.FIELD, DragTypes.AREA],
    drop: (item, monitor) => {
      const imageTop = window.pageYOffset +
        imageRef.current.getBoundingClientRect().top;

      const left = monitor.getClientOffset().x - (
        monitor.getInitialClientOffset().x -
        monitor.getInitialSourceClientOffset().x
      );

      const top = monitor.getClientOffset().y - imageTop - (
        monitor.getInitialClientOffset().y -
        monitor.getInitialSourceClientOffset().y
      );

      const newAreas = [...areas];

      if (DragTypes.AREA === monitor.getItemType()) {
        const updateArea = newAreas.find(area => item.id === area.id);
        updateArea.left = Math.round(left);
        updateArea.top = Math.round(top);
      } else {
        const newArea = {
          field: item.fieldId,
          left: Math.round(left),
          top: Math.round(top),
          page,
        };

        if (1 === newArea.field) { // Add Text
          newArea.body = CUSTOM_TEXT;
        } else if (2 === newArea.field) { // Highlight
          newArea.height = 20;
          newArea.width = 100;
        } else if (3 == newArea.field) { // Checkmark
          newArea.body = '✓';
          newArea.font_size = 16;
        } else if ([4,6].includes(newArea.field)) { // Signature Line, Strikeout
          newArea.width = 100;
        } else if (5 == newArea.field) { // Initial Box
          newArea.width = 22;
          newArea.height = 16;
        } else if (7 === newArea.field) { // Add Text
          newArea.body = CUSTOM_TEXT;
          newArea.width = 100;
          newArea.font_size = 10;
        } else if (8 === newArea.field) { // Add Signature
          newArea.width = 100;
        }

        const lastSave = lastSaves[newArea.field];
        if (lastSave) {
          newArea.bold = lastSave.bold;
          newArea.color = lastSave.color;
          newArea.font_size = lastSave.font_size;
          newArea.font_style = lastSave.font_style;
          newArea.height = lastSave.height;
          newArea.underline = lastSave.underline;
          newArea.width = lastSave.width;
        }

        newAreas.push(newArea);
      }

      setAreas(newAreas);
      saveAreas({ id, areas: newAreas, reload: true });
    }
  });

  const liveUpdateArea = area => {
    const index = areas.findIndex(existing => existing.id === area.id)
    const newAreas = [...areas];
    newAreas[index] = area;
    setAreas(newAreas);
  };

  const saveAreaUpdates = area => {
    if (!area) {
      axios.get(`/zip_upload_items/${id}`).then(({ data }) => {
        setAreas(getAreas(data, page));
      });
      return;
    }

    setLastSaves({ ...lastSaves, [area.field]: area });
    saveAreas({ id, areas: [area] });
    const index = areas.findIndex(existing => existing.id === area.id)
    const newAreas = [...areas];
    if (area._destroy) {
      newAreas.splice(index, 1);
    } else {
      newAreas[index] = area;
    }
    setAreas(newAreas);
  };

  return (
    <div
      ref={drop}
      style={{ position: 'absolute', width: 612 }}
    >
      <img
        ref={imageRef}
        src={imageUrl}
        style={{backgroundColor: 'white', border: '1px solid black'}}
      />
      {areas.map((area, index) => (
        <Area
          key={area.id || `new-${index}`}
          area={area}
          liveUpdateArea={liveUpdateArea}
          saveUpdates={saveAreaUpdates}
          signatureTypes={signatureTypes}
        />
      ))}
    </div>
  );
};

const ZipItemEdit = props => {
  const { zipUploadItem, imageUrl, fieldTypes, signatureTypes } = props;

  const [signedUrl, setSignedUrl] = useState();

  const filename = props.zipUploadItem.name;
  const dotPosition = filename.lastIndexOf('.');
  const baseName = dotPosition > 0 ? filename.substring(0, dotPosition) :
    filename;
  const extension = dotPosition > 0 ? filename.substring(dotPosition) : '';

  const [newBaseName, setNewBaseName] = useState(baseName);
  const [page, setPage] = useState(0);
  const [lastSaves, setLastSaves] = useState({});

  useEffect(() => {
    const pageImageUrl = imageUrl.split('.').slice(0, -1).join('.') +
      `-${page}.png`;

    $.ajax({
      type: 'GET',
      url: '/zip_upload_items/signed_url',
      data: { url: pageImageUrl },
      success(data) {
        setSignedUrl(data.url);
      },
    });
  }, [page, imageUrl]);

  const updateFilename = () => {
    const token = document.querySelector('[name=csrf-token]').content

    axios({
      method: 'PUT',
      url: `/zip_upload_items/${props.zipUploadItem.id}`,
      headers: { 'X-CSRF-TOKEN': token },
      data: { name: newBaseName + extension }
    }).then(({ data }) => {
      window.parent.updatePreviewPanel(zipUploadItem.zip_id);
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        Page
        <select onChange={e => setPage(parseInt(e.target.value))}>
          {[...Array(zipUploadItem.file_page_count)].map((_e, pageIndex) => (
            <option key={pageIndex} value={pageIndex}>
              {pageIndex + 1} of {zipUploadItem.file_page_count}
            </option>
          ))}
        </select>

        {' | '}
        File Name:{' '}
        <input
          type="text"
          size="50"
          value={newBaseName}
          onChange={e => setNewBaseName(e.target.value)}
        />

        <button onClick={() => updateFilename()}>Update File Name</button>

        <br />

        {fieldTypes.map(([ fieldId, label ]) => (
          <FieldType key={fieldId} fieldId={fieldId} label={label} />
        ))}

        <div style={{position: 'relative'}}>
          {signedUrl && (
            <Page
              id={zipUploadItem.id}
              imageUrl={signedUrl}
              fieldTypes={fieldTypes}
              signatureTypes={signatureTypes}
              page={page}
              lastSaves={lastSaves}
              setLastSaves={setLastSaves}
            />
          )}
        </div>
      </div>
    </DndProvider>
  );
};

export default ZipItemEdit;
