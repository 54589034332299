function update_user_log_position(position, user_log_id) {
  $.post('/geoloc', {
    id: user_log_id,
    geoloc: {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy
    }
  });
}

function get_position_for_user_log(user_log_id) {
  if (user_log_id && navigator.geolocation)
    navigator.geolocation.getCurrentPosition(update_user_log_position);
}

window.set_viewport_info = function(user_log_id) {
  if (user_log_id) {
    $.post('/viewport_info', {
      id: user_log_id,
      screen_height: window.screen.availHeight,
      screen_width: window.screen.availWidth
    });
  }
}
