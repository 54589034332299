import React, {useState} from "react"
import Modal from "react-bootstrap/Modal"
// import "bootstrap/dist/css/bootstrap.min.css"
// import "/app/assets/stylesheets/modal-bootstrap.min.css"
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const headerStyle = {
  backgroundColor: '#000'
}

const EmailPhoneModal = (props) => {
  const {
    setShowModal,
    resourceType,
    resources: initialResources,
    resourceOwnerId,
    clientId,
    policyId,
    resourceId,
    clientResourceId,
    policyResourceIds,
    formType,
    prospect
  } = props
  const [resources, setResources] = useState(initialResources);
  const [resourceToSave, setResourceToSave] = useState('')
  const [saveValue, setSaveValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [newResource, setNewResource] = useState(false)
  const [defaultResourceId, setDefaultResourceId] = useState(resourceId)
  const [clientDefaultResourceId, setClientDefaultResourceId] = useState(clientResourceId)
  const [changesMade, setChangesMade] = useState(false)

  const [formTypeActual, setFormTypeActual] = useState('')
  if(formTypeActual === '') {
    if(prospect && formType === 'prospects') {
      setFormTypeActual('prospects')
    }else if (prospect) {
      setFormTypeActual('policies')
    }else {
      setFormTypeActual(formType)
    }
  }

  const makeDefault = (e, resource) => {
    e.preventDefault()

    setLoading(true)
    let url = resourceType === 'client_phones' ?
      `/${formTypeActual}/${formTypeActual === 'policies' && policyId !== undefined ? policyId : resourceOwnerId}/set_default_phone` :
      `/${formTypeActual}/${formTypeActual === 'policies' && policyId !== undefined ? policyId : resourceOwnerId}/set_default_email`

    const token = document.querySelector('[name=csrf-token]').content

    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    let data = null
    if(formTypeActual === 'clients') {
      data = resourceType === 'client_phones' ?
        {
          client_id: resourceOwnerId,
          default_phone_id: resource.id
        } :
        {
          client_id: resourceOwnerId,
          default_email_id: resource.id
        }

      axios({
        method: "PUT",
        url: url,
        data: {
          client: data
        }
      }).then((res) => {
        res.data.resources.map((resource) => {
          if(resource.default) {
            setDefaultResourceId(resource.id)
            if(resource.owner_type === 'client') {
              setClientDefaultResourceId(resource.id)
            }
          }
        })
        setResources(res.data.resources)
        setChangesMade(true)
        setLoading(false)
      })
    }else if(formTypeActual === 'prospects') {
      data = resourceType === 'client_phones' ?
        {
          client_id: resourceOwnerId,
          default_phone_id: resource.id
        } :
        {
          client_id: resourceOwnerId,
          default_email_id: resource.id
        }

      axios({
        method: "PUT",
        url: url,
        data: {
          prospect: data
        }
      }).then((res) => {
        res.data.resources.map((resource) => {
          if(resource.default) {
            setDefaultResourceId(resource.id)
          }
        })
        setResources(res.data.resources)
        setChangesMade(true)
        setLoading(false)
      })
    }else {
      data = resourceType === 'client_phones' ?
        {
          policy_id: policyId !== undefined ? policyId : resourceOwnerId,
          default_phone_id: resource.id
        } :
        {
          policy_id: policyId !== undefined ? policyId : resourceOwnerId,
          default_email_id: resource.id
        }
      axios({
        method: "PUT",
        url: url,
        data: {
          policy: data
        }
      }).then((res) => {
        res.data.resources.map((resource) => {
          if(resource.default) {
            setDefaultResourceId(resource.id)
          }
        })
        setResources(res.data.resources)
        setChangesMade(true)
        setLoading(false)
      })
    }
  }

  const deleteResource = (e, resource) => {
    e.preventDefault()

    setLoading(true)

    let url = `/${formTypeActual}/${resourceOwnerId}/${resourceType}/${resource.id}`
    const token = document.querySelector('[name=csrf-token]').content

    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    axios({
      method: "DELETE",
      url: url,
      data: {
        resource: { resource_id: resource.id }
      }
    }).then((res) => {
      if(res.data.status === 200) {
        setResources(res.data.resources)
        setChangesMade(true)
        setLoading(false)
      }else {
        setErrorMessage(res.data.message)
      }
    })
  }

  let formatPhoneNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');

    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\d{2,})?$/);
    if (match) {
      let ext = (match[5] ? '+' : '')
      return ['(', match[2], ') ', match[3], '-', match[4], ext, match[5]].join('')
    }

    return null;
  }

  const makeRequest = (path, method, data) => {
    setLoading(true)

    const url = `/${formTypeActual}/${resourceOwnerId}/${resourceType}/${path}`

    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    axios({ method, url, data: { resource: data } }).then(res => {
      if (200 === res.data.status) {
        setResources(res.data.resources)
        setChangesMade(true)
        setLoading(false)
        setNewResource(false)
      }
    })
  }

  const createResource = (e) => {
    e.preventDefault()

    var cleaned = resourceType === 'client_phones' ?
      saveValue.replace(/(^1)?(\D)/g, '') :
      saveValue.match(/[A-Za-z0-9][A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+[A-Za-z]/)

    if(resourceType === 'client_emails' && cleaned === null) {
      setLoading(false)
      setShowError(true)
      setErrorMessage('Invalid Email')
      return null
    }

    const data = resourceType === 'client_phones' ?
      {
      client_id: clientId,
      policy_id: policyId || null,
      phone: cleaned
    } :
    {
      client_id: clientId,
      policy_id: policyId || null,
      email: cleaned[0]
    }

    makeRequest('', 'POST', data)
  }

  const showCreateInput = () => {
    return (
      <>
        <input
          type='text'
          placeholder={`Add Client ${resourceType === 'client_phones' ?
          'Phone Number' : 'Email'}`}
          style={{width: '50%'}}
          onChange={(event) => setSaveValue(event.target.value)}
        />
        <button onClick={(event) => createResource(event)}>
          {`Update ${resourceType === 'client_phones' ? 'Phone' : 'Email'}`}
        </button>
        <a href='#' style={{paddingLeft: '10px'}}
          onClick={(event) => {
            event.preventDefault()
            setShowError(false)
            setNewResource(false)
          }
        }>
          cancel
        </a>
        {showError ?
          <span style={{color: 'red', 'marginLeft': '10px'}}>{errorMessage}</span> :
          null
        }
      </>
    )
  }

  const editResource = (e, resource) => {
    e.preventDefault()
    setResourceToSave(resource)
  }

  const updateResource = (e) => {
    e.preventDefault()
    setLoading(true)
    var cleaned = resourceType === 'client_phones' ?
      saveValue.replace(/(^1)?(\D)/g, '') :
      saveValue.match(/[A-Za-z0-9][A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+[A-Za-z]/)
    if(resourceType === 'client_emails' && cleaned === null) {
      setLoading(false)
      setShowError(true)
      setErrorMessage('Invalid Email')
      return null
    }
    let data = resourceType === 'client_phones' ?
      {
        client_phone_id: resourceToSave.id,
        phone: cleaned
      } :
      {
        client_email_id: resourceToSave.id,
        email: cleaned[0]
      }

    let url = `/${formTypeActual}/${resourceOwnerId}/${resourceType}/${resourceToSave.id}`
    const token = document.querySelector('[name=csrf-token]').content

    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    axios({
      method: "PUT",
      url: url,
      data: {
        resource: data
      }
    }).then((res) => {
      if(res.data.status === 200) {
        setResources(res.data.resources)
        setChangesMade(true)
        setResourceToSave('')
        setLoading(false)
      }else {
        setErrorMessage(res.data.message)
      }
    })
  }

  const showEditInput = () => {
    return (
      <>
        <input
          type="text"
          defaultValue={resourceType === 'client_phones' ?
            formatPhoneNumber(resourceToSave.phone) :
            resourceToSave.email
          }
          style={{width: '50%'}}
          onChange={(event) => setSaveValue(event.target.value)}
        />
        <button onClick={(event) => updateResource(event)}>
          {`Update ${resourceType === 'client_phones' ? 'Phone' : 'Email'}`}
        </button>
        <a href='#' style={{paddingLeft: '10px'}}
          onClick={(event) => {
            event.preventDefault()
            setShowError(false)
            setResourceToSave('')
          }
        }>
          cancel
        </a>
        {showError ?
          <span style={{color: 'red', 'marginLeft': '10px'}}>{errorMessage}</span> :
          null
        }
      </>
    )
  }

  const getDefaultLabels = (resource) => {
    if (formTypeActual !== 'clients' && (defaultResourceId === resource.id) ||
        (formTypeActual === 'clients' && clientDefaultResourceId === resource.id)) {
      return (
        <b>default</b>
      )
    }else {
      return (
        <a href="#" onClick={(event) => makeDefault(event, resource)}>
          {`make ${(formTypeActual === 'clients' || formTypeActual === 'prospects') ?
            formTypeActual === 'clients' ? 'client' : 'prospect' :
            'policy'} default`}
        </a>
      )
    }
  }

  const getAlternateDefaultLabel = (resource) => {
    if(formTypeActual === 'policies' && clientDefaultResourceId === resource.id) {
    //   return ` (${formTypeActual === 'clients' ? 'Client' :
    //     formTypeActual === 'policies' ? 'Policy' : 'Prospect'} Default)`
    // }else {
      return null
    }
  }

  const getMakeDefaultLink = (resource) => {
    if((formTypeActual === 'clients' || formTypeActual === 'prospects') &&
      defaultResourceId !== resource.id) {
        return (
          policyResourceIds.map((policy) => {
            if(resource.id === policy['default_resource_id']) {
              return (
                <a href={`/policies/${policy['policy_id']}`}>
                  {` (Policy ${policy['policy_id']} Default)`}
                </a>
              )
            }else {
              return null
            }
          })
        )
    }else {
      return null
    }
  }

  const getDeleteLinks = (resource) => {
    // if (defaultResourceId !== resource.id) {
      // if(formTypeActual === 'clients' || formTypeActual === 'prospects') {
      //   return (
      //     policyResourceIds.map((policy) => {
      //       if(!prospect && resource.id !== policy['default_resource_id']) {
      //         return (
      //           <a href="#" onClick={(event) => deleteResource(event, resource)}>
      //             {` | delete`}
      //           </a>
      //         )
      //       }else {
      //         return null
      //       }
      //     })
      //   )
      // }else if(formTypeActual === 'policies' && clientDefaultResourceId !== resource.id) {
      //   return (
      //     <a href="#" onClick={(event) => deleteResource(event, resource)}>
      //       {` | delete`}
      //     </a>
      //   )
      // }else {
      //   return null
      // }
    //
    // }else {
    //   return null
    // }
    return (
      <a href="#" onClick={(event) => deleteResource(event, resource)}>
        {` | delete`}
      </a>
    )
  }

  const showResourceTable = () => {
    let disables = []
    return (
      <table className="table table-striped">
        <tbody>
          {resources.map((resource) => {
            return(
              <tr key={`resource-row-${resource.id}`}>
                <td
                  style={{
                    paddingLeft: '10px',
                    color: (resource.disabled ? 'red' : 'black')
                  }}
                >
                  {resourceType === 'client_phones' ?
                    formatPhoneNumber(resource.phone) :
                    resource.email
                  }
                  {getAlternateDefaultLabel(resource)}
                  {getMakeDefaultLink(resource)}
                </td>
                <td>
                  {getDefaultLabels(resource)}
                  <span>
                    {' | '}
                    <a href="#" onClick={(event) => editResource(event, resource)}>
                      edit
                    </a>
                  </span>
                  <span>
                    {getDeleteLinks(resource)}
                  </span>
                </td>
                {'client_emails' === resourceType && (
                  <td>{resource.status}</td>
                )}
                {showError ?
                  <span style={{color: 'red', 'marginLeft': '10px'}}>
                    {errorMessage}
                  </span> :
                  null
                }
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const showContent = () => {
    if(newResource) {
      return showCreateInput()
    }
    return (
      resourceToSave !== '' ? showEditInput() : showResourceTable()
    )
  }

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShowModal(false);
          if(changesMade) {
            window.location.reload();
          }
        }}
        centered
        size="lg"
        dialogClassName={headerStyle}
      >
        <Modal.Header>
          <div style={{display: 'inline-flex', 'alignItems': 'center'}}>
            <Modal.Title style={{paddingRight: '20px'}}>
              {`Edit ${resourceType === 'client_phones' ?
                'Phones' : 'Emails'}`}<br />
            </Modal.Title>
            {!newResource ?
              <FontAwesomeIcon
                icon={faPlusCircle}
                color='green'
                size='1x'
                onClick={(event) => setNewResource(true)}
              /> :
              null
            }
            {'client_emails' === resourceType && (
              <a
                style={{paddingLeft: 20}}
                href={`/${prospect ? 'prospects' : 'clients'}/${clientId}/email_trackings`}
              >
                Email Delivery Tracking
              </a>
            )}
          </div>
          <button onClick={() => {
              setShowModal(false);
              window.location.reload();
            }
          }>
            Done
          </button>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <div>
              <br />
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div> :
            showContent()
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmailPhoneModal

//
// <div className='Modal' style={{display: 'show'}}
//   // show={true}
//   // onHide={() => setShowModal(false)}
//   // centered
//   // size="lg"
//   // dialogClassName={headerStyle}
// >
//   <div className='modal-header'>
//     <div style={{display: 'inline-flex', 'alignItems': 'center'}}>
//       <div className='modal-title' style={{paddingRight: '20px'}}>
//         {`Edit ${resourceType === 'client_phones' ?
//           'Phones' : 'Emails'}`}<br />
//       </div>
//       {!newResource ?
//         <FontAwesomeIcon
//           icon={faPlusCircle}
//           color='green'
//           size='1x'
//           onClick={(event) => setNewResource(true)}
//         /> :
//         null
//       }
//     </div>
//   </div>
//   <div className='modal-body'>
//     {loading ?
//       <div>
//         <br />
//         <Spinner animation="border" role="status" variant="primary">
//           <span className="sr-only">Loading...</span>
//         </Spinner>
//       </div> :
//       showContent()
//     }
//   </div>
// </div>
