import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import ESResultOption from './ESResultOption';
// import { AUTH_TOKEN } from '../constants';
// import '../../assets/stylesheets/elasticsearchbox.css'
import axios from 'axios';

const asTypeaheadContainer = {
  width: '100%',
  paddingTop: '4px',
  paddingBottom: '10px'
}

const asTypeahead = {
  width: '100%'
}

const ElasticsearchBox = ({ branch_ids }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const history = useHistory();
  const esTextInput = useRef(null);
  const hideMenu = () => {
    esTextInput.current.hideMenu();
    esTextInput.current.clear();
  }

  const handleSearch = term => {
    setIsLoading(true);

    axios.get('/search/elastic', { params: { term } }).then(({ data }) => {
      console.log(data);
      setMenuOptions(data.hits);
      setIsLoading(false);
    });
  }

  return (
    <div style={asTypeaheadContainer}>
      <AsyncTypeahead
        id="async-search"
        isLoading={isLoading}
        labelKey={option =>
          `${option._id}`
        }
        minLength={3}
        onSearch={handleSearch}
        onInputChange={handleSearch}
        options={menuOptions}
        placeholder="Search:"
        renderMenu={(options, menuProps) => {
          const results = menuOptions.map((option, position) => {
            return (
              <ESResultOption
                option={option}
                position={position}
                key={`${option._index}${option._id}`}
                hideMenu={hideMenu}
              />
            )
          })

          return (
            <Menu {...menuProps}>{results}</Menu>
          )
        }}
        style={asTypeahead}
        ref={esTextInput}
      />
    </div>
  )
}

export default ElasticsearchBox;
