import React, { useState } from "react"
import axios from 'axios'
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { handleSubmit } from './HandleSubmit'

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const troubleTicketSchema = yup.object({
  name: yup
    .string(),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required('Required'),
  phone: yup
    .string()
    .required("Required")
    .matches(phoneRegExp, {
      message: "Please enter a valid number.",
      excludeEmptyString: false,
    }),
});

const TroubleTicket = () => {
  const [formValues, setFormValues] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [alert, displayAlert] = useState({ message: null, variant: null });

  const submitTicket = (values) => {
    handleSubmit(values, setFormSubmitting, displayAlert, setSubmitted);
  }

  console.log('referrer', document.referrer)
  return (
    <>
      {!submitted ?
        <>
          <h3>Submit a new trouble ticket</h3>
          <Formik
            initialValues={{
              name: '',
              phone: '',
              email: '',
              url: '',
              subject: '',
              problem: '',
              expectation: '',
              supplied_url: document.referrer,
            }}
            validate={values => {
              const errors = {};
              if (!values.phone) {
                errors.phone = 'Phone Required';
              } else if (
                !/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(values.phone)
              ) {
                errors.phone = 'Invalid phone number';
              }
              if (!values.email) {
                errors.email = 'Email Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                submitTicket(values)
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <>
                <Form>
                  <label className='input-label'>
                    Name:
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='name'
                      key='name'
                    />
                  </div>
                  <label className='input-label'>
                    Phone*:
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='phone'
                      key='phone'
                    />
                  </div>
                  <ErrorMessage name="phone" component="div" className='input-error'/>
                  <label className='input-label'>
                    Email*:
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='email'
                      name='email'
                      key='email'
                    />
                  </div>
                  <ErrorMessage name="email" component="div" className='input-error'/>
                  <label className='input-label'>
                    What was the URL of the issue?
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='url'
                      key='url'
                    />
                  </div>
                  <label className='input-label'>
                    In just a few words, what isn't working correctly?:
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='subject'
                      key='subject'
                    />
                  </div>
                  <label className='input-label'>
                    Please describe in detail what you were trying to do and what went wrong:
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='problem'
                      key='problem'
                    />
                  </div>
                  <label className='input-label'>
                    What were you expecting to happen?
                  </label>
                  <div className='input-text padded-bottom-input'>
                    <Field
                      type='text'
                      name='expectation'
                      key='expectation'
                    />
                  </div>
                  <div>
                    <button
                      type='submit'
                      className='btn-outline-primary'
                      style={{marginTop: '25px'}}
                    >
                      Send
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </>  :
        <>
          <h3>{ alert.message }</h3>
        </>
      }
    </>
  )
}

export default TroubleTicket
