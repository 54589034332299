import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Container, Label, InputContainer, Button } from './ZipRename';

const Select = styled.select`
  flex-grow: 1;
`;

const ZipFolder = ({ zipUpload, folderOptions }) => {
  const [savedFolder, setSavedFolder] = useState(zipUpload?.folder.toFixed());
  const [folder, setFolder] = useState(
    zipUpload?.folder.toFixed() || '1'
  );

  const handleSubmit = () => {
    const token = document.querySelector('[name=csrf-token]').content

    axios({
      method: 'PUT',
      url: `/zip_uploads/${zipUpload.id}`,
      headers: { 'X-CSRF-TOKEN': token },
      data: { upload: { folder } }
    })

    setSavedFolder(folder);
  };

  return (
    <Container>
      <Label>Folder</Label>
      <InputContainer>
        <Select
          name="grouped-form-folder"
          className="grouped-form-folder"
          value={folder}
          onChange={e => setFolder(e.target.value)}
        >
          {folderOptions.map(([folderId, folderName]) => (
            <option key={folderId} value={folderId.toFixed()}>
              {folderName}
            </option>
          ))}
        </Select>
        <Button
          disabled={folder === savedFolder}
          onClick={handleSubmit}
        >✓</Button>
      </InputContainer>
    </Container>
  );
};

export default ZipFolder;
