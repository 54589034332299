import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 8px;
  background-color: #C8EBFB;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Label = styled.label`
  margin-bottom: -2px;
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  flex-grow: 1;
`;

export const Button = styled.button`
  width: 30px;
`;

const ZipRename = ({ zipUpload }) => {
  const [savedName, setSavedName] = useState(zipUpload?.name);
  const [name, setName] = useState(
    zipUpload?.name || `Grouped Forms ${moment().format('M-D-YY')}`
  );

  const handleSubmit = () => {
    const token = document.querySelector('[name=csrf-token]').content

    axios({
      method: 'PUT',
      url: `/zip_uploads/${zipUpload.id}`,
      headers: { 'X-CSRF-TOKEN': token },
      data: { upload: { name } }
    });

    setSavedName(name);
  };

  return (
    <Container>
      <Label>ZIP File Name</Label>
      <InputContainer>
        <Input
          name="grouped-form-file-name"
          className="grouped-form-name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Button
          disabled={name === savedName}
          onClick={handleSubmit}
        >✓</Button>
      </InputContainer>
    </Container>
  );
};

export default ZipRename;
