// import $ from 'jquery';
// global.$ = $
// global.jQuery = $

import accounting from 'accounting-js';

import moment from 'moment';
import Sortable from 'sortablejs';

import 'controllers';

require('@rails/ujs').start();
require('turbolinks').start();
// require("channels")
require('@rails/activestorage').start();
// require("jquery-ui")

// require("bootstrap")

require('src/dataTables_extensions');

require('src/user-logs');

global.accounting = accounting;

const images = require.context('../images', true);
const imagePath = name => images(name, true);
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);
