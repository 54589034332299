import dt from 'datatables.net';
dt(window, $);
// import 'datatables.net-bs4/css/dataTables.bootstrap4.css'

const appColumns = [
  { data: 'program' },
  { data: 'businessName' },
  { data: 'address' },
  { data: 'contact' },
  { data: 'phone' },
  { data: 'submitted' },
  { data: 'actions' },
]

const appSmallColumns = [
  { data: 'program' },
  { data: 'contact' },
  { data: 'phone' },
  { data: 'submitted' },
  { data: 'actions' }
]

const appAssignColumns = [
  { data: '' },
]

const batchCreditColumns = [
  { data: 'created' },
  { data: 'filters' },
  { data: 'createdBy' },
  { data: 'actions' }
]

const branchColumns = [
  { data: 'name' },
  { data: 'code' },
  { data: 'address' },
  { data: 'phone' },
  { data: 'actions' }
]

const brokerColumns = [
  { data: 'name' },
  { data: 'address' },
  { data: 'phone' },
  { data: 'actions' }
]

const clientColumns = [
  { data: 'name' },
  { data: 'state' },
  { data: 'phone' },
  { data: 'email' },
  { data: 'license' },
  { data: 'policyNums' },
  { data: 'actions' }
]

const importColumns = [
  { data: 'branch' },
  { data: 'description' },
  { data: 'status' },
  { data: 'type' },
  { data: 'filename' },
  { data: 'importBy' },
  { data: 'time' },
  { data: 'actions' }
]

const importProspectColumns = [
  { data: 'from' },
  { data: 'effect' },
  { data: 'name' },
  { data: 'state' },
  { data: 'phone' },
  { data: 'email' },
  { data: 'disposition' },
  { data: 'status' },
  { data: 'actions' },
]

const importRecordColumns = [
  { data: 'from' },
  { data: 'to' },
  { data: 'effect' },
  { data: 'data' }
]

const insuranceAppColumns = [
  { data: 'state' },
  { data: 'businessName' },
  { data: 'address' },
  { data: 'contact' },
  { data: 'phone' },
  { data: 'submitted' },
  { data: 'actions' }
]

const policyColumns = [
  { data: 'name' },
  { data: 'program' },
  { data: 'policy' },
  { data: 'issue' },
  { data: 'effective' },
  { data: 'term' },
  { data: 'expires' },
  { data: 'paid' },
  { data: 'actions' }
]

const policyStatusColumns = [
  { data: 'code' },
  { data: 'name' },
  { data: 'active' },
  { data: 'roles' },
  { data: 'actions' }
]

const prospectColumns = [
  { data: 'name' },
  { data: 'state' },
  { data: 'phone' },
  { data: 'email' },
  { data: 'disposition' },
  { data: 'touched' },
  { data: 'renews' },
  { data: 'status' },
  { data: 'actions' }
]

const reportColumns = [
  { data: 'program' },
  { data: 'surety' },
  { data: 'name' },
  { data: 'filename' },
  { data: 'actions' }
]

const suretyColumns = [
  { data: 'name' },
  { data: 'address' },
  { data: 'phone' },
  { data: 'status' },
  { data: 'next' },
  { data: 'actions' }
]

const userLogColumns = [
  { data: 'createdAt' },
  { data: 'ipAddress' },
  { data: 'description' }
]

const getColumns = (tableName) => {
  switch(tableName) {
    case 'apps_assign_dt':
      return { columns: appAssignColumns, 'no-search': [2,4,6] };
      break;
    case 'apps_dt':
      return { columns: appColumns, 'no-search': [2,4,6] };
      break;
    case 'apps_dt_small':
      return { columns: appSmallColumns, 'no-search': [2,4] };
      break;
    case 'batch_credits_dt':
      return { columns: batchCreditColumns, 'no-search': [1,3] };
      break;
    case 'branches_dt':
      return { columns: branchColumns, 'no-search': [2,3,4] };
      break;
    case 'brokers_dt':
      return { columns: brokerColumns, 'no-search': [1,2,3] };
      break;
    case 'clients_dt':
      return { columns: clientColumns, 'no-search': [5,6] };
      break;
    case 'imports_dt':
      return { columns: importColumns, 'no-search': [0,2,3,7] };
      break;
    case 'import_prospects_dt':
      return { columns: importProspectColumns, 'no-search': [0,3,4,5,6,7,8] };
      break;
    case 'import_records_dt':
      return { columns: importRecordColumns, 'no-search': [2] };
      break;
    case 'insurance_apps_dt':
      return { columns: insuranceAppColumns, 'no-search': [2,4,6] };
      break;
    case 'policies_dt':
      return { columns: policyColumns, 'no-search': [1,8] };
      break;
    case 'policy_statuses_dt':
      return { columns: policyStatusColumns, 'no-search': [2,3,4] };
      break;
    case 'prospects_dt':
      return { columns: prospectColumns, 'no-search': [8] };
      break;
    case 'reports_dt':
      return { columns: reportColumns, 'no-search': [] };
      break;
    case 'sureties_dt':
      return { columns: suretyColumns, 'no-search': [1,2,3,5] };
      break;
    case 'user_logs_dt':
      return { columns: userLogColumns, 'no-search': [2] };
      break;
    default:
      break;
  }
}

$(document).on('turbolinks:load', function() {
  $('.ajax-datatable').each(function() {
    let columnsInfo = getColumns($(this).attr('table-name'));
    $(this).dataTable({
      processing: true,
      serverSide: true,
      jQueryUI: false,
      stateSave: true,
      stateSaveParams: function (settings, data) {
        data.start = 0;
      },
      dom: `<'fg-toolbar ui-widget-header ui-corner-tl ui-corner-tr ui-helper-clearfix'<"H"lf>>t<'ui-state-default'<'th'>><'fg-toolbar ui-widget-header ui-corner-bl ui-corner-br ui-helper-clearfix'<"F"ip>>`,
      ajax: {
        url: $(this).data('source')
      },
      pagingType: 'full_numbers',
      displayLength: ($(this).data('display-length') || 10),
      aaSorting: [[$(this).data('sortcol') || 0, $(this).data('sortdir') || 'asc']],
      "fnDrawCallback": function(oSettings) {
        if (oSettings && oSettings._iDisplayStart > oSettings.fnRecordsDisplay()) {
          oTable = $(this).dataTable();
          setTimeout( function() {
            oTable.fnPageChange(0, true);
          }, 0 );
        }
      },
      columns: columnsInfo['columns'],
      columnDefs: [ { orderable: false, targets: columnsInfo['no-search'] } ],
      stripeClasses:['stripe-odd','stripe-even']
    });
  });
});

// $(document).ready(function() {
//   $('.ajax-datatable').each(function() {
//     $(this).dataTable({
//       bProcessing: true,
//       bServerSide: true,
//       bJQueryUI: true,
//       bStateSave: true,
//       sAjaxSource: $(this).data('source'),
//       sPaginationType: 'full_numbers',
//       iDisplayLength: ($(this).data('display-length') || 10),
//       aaSorting: [[$(this).data('sortcol') || 0, $(this).data('sortdir') || 'asc']],
//       "fnDrawCallback": function(oSettings) {
//         if (oSettings && oSettings._iDisplayStart > oSettings.fnRecordsDisplay()) {
//           oTable = $(this).dataTable();
//           setTimeout( function() {
//             oTable.fnPageChange(0, true);
//           }, 0 );
//         }
//       }
//     });
//   });
// });

