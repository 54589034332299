import React, { useEffect, useState, useRef, useContext, TouchableOpacity } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const esOptionContainer = {
  display: 'flex',
  borderBottom: '1px solid #ccc',
  marginTop: '0px',
  width: '100%',
  justifyContent: 'space-between',
}

const formatPhone = value => {
  if (value && 10 === value.length) {
    const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }

  return value;
};

const formatSSN = value => {
  if (value && 9 === value.length) {
    const match = value.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
  }

  return value;
};

const clientPath = (status, id) => (
  `/${'Active' === status ? 'clients' : 'prospects'}/${id}`
);

const ResultData = ({children}) => (
  <div style={{display: 'block', fontSize: 12, padding: 4}}>
    {children}
  </div>
);

const Label = ({children}) => (
  <label className="es-option-label">{children}</label>
);

const Value = ({children}) => (
  <div className="es-option-value">{children}</div>
);

const Values = ({children}) => (
  <div className="es-option-values">{children}</div>
);

const Highlight = ({children}) => (
  <div className="es-highlight">{children}</div>
);

const Status = ({children}) => (
  <div className="es-status">{children}</div>
);

const Branch = ({id}) => {
  if (!id) { return null; }

  const code = (1 === id ? 'FL' : 'WA');

  return (
    <div className="es-branch">{code}</div>
  );
};

const ESResultOption = (props) => {
  const { option, hideMenu } = props;
  const data = option._source;
  const type = option._index;
  const id = option._id;

  switch (type) {
    case 'policies':
      return (
        <a href={`/policies/${id}`}>
          <div style={esOptionContainer} className='es-option-container'>
            <ResultData>
              <Value>
                <Label>Policy Number</Label>
                {data.full_policy_number}
              </Value>
              {data.principal_name && (
                <Value>
                  <Label>Client Name</Label>
                  {data.principal_name}
                </Value>
              )}
              <Value>
                <Label>Business Name</Label>
                {data.name}
              </Value>
              {data.address && (
                <Value>
                  <Label>Address</Label>
                  {data.address}
                </Value>
              )}
              <Values>
                <Value>
                  <Label>Program</Label>
                  {data.pr_state}
                </Value>
                {data.surety && (
                  <Value>
                    <Label>Surety</Label>
                    {data.surety.substr(0, 40)}
                  </Value>
                )}
                {data.invoice_numbers && (
                  <Value>
                    <Label>Invoice</Label>
                    {data.invoice_numbers.slice(-1)[0]}
                  </Value>
                )}
              </Values>
            </ResultData>
            <Highlight>
              <Status>{data.status}</Status>
              <Branch id={data.branch_id} />
            </Highlight>
          </div>
        </a>
      )
      break;
    case 'clients':
      return (
        <a href={clientPath(data.client_status, id)}>
          <div style={esOptionContainer} className="es-option-container">
            <ResultData>
              {data.principal_name && (
                <Value>
                  <Label>Client Name</Label>
                  {data.principal_name}
                </Value>
              )}
              <Value>
                <Label>Business Name</Label>
                {data.name}
              </Value>
              {data.address && (
                <Value>
                  <Label>Address</Label>
                  {data.address}
                </Value>
              )}
              <Values>
                <Value>
                  <Label>License</Label>
                  {data.license}
                </Value>
                {data.phone && data.phone.length > 0 && (
                  <Value>
                    <Label>Phone</Label>
                    {data.phone.map((phone, index) => (
                      <span key={index}>{formatPhone(phone)}{' '}</span>
                    ))}
                  </Value>
                )}
                {data.email && data.email.length > 0 && (
                  <Value>
                    <Label>Email</Label>
                    {data.email.map((email, index) => (
                      <span key={index}>{email}{' '}</span>
                    ))}
                  </Value>
                )}
              </Values>
            </ResultData>
            <Highlight>
              <Status>
                {'Active' === data.client_status ?
                  'Client' : data.client_status
                }
              </Status>
              <Branch id={data.branch_id} />
            </Highlight>
          </div>
        </a>
      )
      break;
    case 'client_users':
      return (
        <a href={clientPath(data.client_status, data.client_id)+'#tabs-people'}>
          <div style={esOptionContainer} className='es-option-container'>
            <ResultData>
              <Value>
                <Label>Person Name</Label>
                {data.full_name}
              </Value>
              <Value>
                <Label>Client Name</Label>
                {data.client_name}
              </Value>
              <Values>
                <Value>
                  <Label>SSN</Label>
                  {formatSSN(data.ssn)}
                </Value>
                {data.phone && (
                  <Value>
                    <Label>Phone</Label>
                    {formatPhone(data.phone)}
                  </Value>
                )}
                {data.cell_phone && (
                  <Value>
                    <Label>Cell Phone</Label>
                    {formatPhone(data.cell_phone)}
                  </Value>
                )}
                {data.work_phone && (
                  <Value>
                    <Label>Work Phone</Label>
                    {formatPhone(data.work_phone)}
                  </Value>
                )}
                {data.email && (
                  <Value>
                    <Label>Email</Label>
                    {data.email}
                  </Value>
                )}
              </Values>
            </ResultData>
            <Highlight>
              <Status>Person</Status>
              <Branch id={data.branch_id} />
            </Highlight>
          </div>
        </a>
      )
      break;
    case 'quotes':
      const path = data.policy_id ? `/policies/${data.policy_id}` :
        clientPath(data.client_status, data.client_id);

      return (
        <a href={path}>
          <div style={esOptionContainer} className="es-option-container">
            <ResultData>
              <Value>
                <Label>Quote Number</Label>
                {data.quote_num}
              </Value>
              <Value>
                <Label>Business Name</Label>
                {data.name}
              </Value>
              {data.principal_name && (
                <Value>
                  <Label>Client Name</Label>
                  {data.principal_name}
                </Value>
              )}
            </ResultData>
            <Highlight>
              <Status>Quote</Status>
              <Branch id={data.branch_id} />
            </Highlight>
          </div>
        </a>
      )
      break;
    default:
      return false;
      break;
  }
}

export default ESResultOption;
