import React,
{
  useEffect,
  useState,
  useRef,
  useContext,
  TouchableOpacity
} from "react"
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import EmailPhoneModal from './EmailPhoneModal'

const PhoneEdit = (props) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div
        className="count-icon"
        style={{display: 'inline-flex', justifyContent: 'space-around',
          width: '35px', marginLeft: '5px'}}
        onClick={(event) => setShowModal(true)}
      >
        <span style={{color: 'green', fontWeight: 'bold'}}>
          {props.phones.length === 0 || props.phones.length === 1 ?
            `` :
            `+${props.phones.length - 1}`
          }
        </span>
        <FontAwesomeIcon
          icon={faEdit}
          color='green'
          size='1x'
          style={{marginRight: '5px', marginTop: '2px'}}
        />
      </div>
      {showModal ?
        <EmailPhoneModal
          setShowModal={setShowModal}
          resourceType={"client_phones"}
          resources={props.phones}
          resourceOwnerId={props.resource_owner_id}
          clientId={props.client_id}
          policyId={props.policy_id}
          resourceId={props.default_phone_id}
          clientResourceId={props.client_default_resource_id}
          policyResourceIds={props.policies_default_phone_ids}
          formType={props.form_type}
          prospect={props.prospect}
        /> :
        null
      }
    </>
  );
}

export default PhoneEdit
