import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      swapThreshold: 0.85,
      ghostClass: 'blue-background-class',
      chosenClass: 'drag-item',
    });
  }

  end(event) {
    const { id } = event.item.dataset;
    const data = new FormData();
    data.append('position', event.newIndex + 1);

    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data,
    });
  }
}
