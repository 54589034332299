import React, { useState } from 'react';
import styled from 'styled-components';

import AddModal from './AddModal';

const Button = styled.button`
  width: 100%;
  background-color: white;
  border: none;
  border-bottom: 1px solid black;
  font-weight: 600;
  text-align: left;
`;

const ZipItemAdd = ({ zipUpload }) => {
  const [showModal, setShowModal] = useState(false);

  const handleHide = () => {
    setShowModal(false);

    $.ajax({
      type: 'GET',
      url: '/zip_uploads/render_preview_panel',
      data: { zip_upload_id: zipUpload.id },
    });
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)}>+ Add From Files</Button>
      {showModal && (
        <AddModal
          zipUpload={zipUpload}
          handleHide={handleHide}
        />
      )}
    </>
  );
};

export default ZipItemAdd;
